import React from 'react';

import circlesData from '../../../data/circels.json';

export const CirclesBlock: React.FC = () => {
  return (
    <>
      <h3>Cirkels</h3>
      <div className="grid grid-cols-2 gap-4">
        {circlesData &&
          circlesData.map((c: any) => {
            return (
              <div key={c.title} className="border border-secondary p-4">
                <img
                  src={`/img/${c.img}`}
                  className="w-full"
                  alt="circle_img"
                />
                <h3>{c.title}</h3>
                <h4>{c.subtitle}</h4>
                <div dangerouslySetInnerHTML={{ __html: c.description }} />
              </div>
            );
          })}
      </div>
    </>
  );
};
