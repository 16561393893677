import React from 'react';
import { CirclesBlock } from '../components/circels/CirclesBlock';
import { Layout } from '../components/shared/Layout';

const CircelsPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Cirkels</h1>
            <p>
              Cirkels zijn groepen mensen die samen deelnemen aan Haagsche
              Schil. Mensen in een cirkel vormen een groep. Op basis van
              locatie, een thema of specifieke mono-stroom.{' '}
            </p>
            <p>
              Iedereen kan een cirkel beginnen. Wanneer er voldoende deelnemers
              zijn voor een cirkel dan kunnen we starten met Haagsche Schil voor
              deze cirkel.
            </p>

            <p>
              Bekijk hieronder welke Haagsche Schil Cirkels al actief zijn.
              Staat jou buurt of monostroom er niet bij?
            </p>

            <p>Neem contact op en start een cirkel!</p>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <CirclesBlock />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default CircelsPage;
